import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { aboutUsLinksChinese } from 'components/Layout/navigation';
import { aboutUsLinksKorean } from 'components/Layout/navigation';
import { aboutUsLinksJapanese } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import Carousel from 'components/Carousel';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium, BaseTransparentP, BaseP } from 'components/typography';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  PartnersWrapper,
  WhiteContainer,
  ImageBox,
  GatsbyImage,
} from 'components/pages/Partners/styled';
import { RelativeWrapperWithImage } from 'components/pages/Integrations/styled';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { DetailContent } from 'components/pages/AboutUs/styled';

const MainTitle = styled.h1`
    margin-top: 40px;
    margin-bottom: 80px;
    font-size: 28px;
    font-weight: 500;
  
  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
  }
  @media only screen and (max-width: 1023px) {
    h1 {
      color: #fff;
    }
  }
`;

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }


`;

const PartnerPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgTruckQuery {
      file(relativePath: { eq: "truck.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const Breadcrumbs = styled.p`
<<<<<<< HEAD
<<<<<<< HEAD
=======
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
   
    padding:25px 0px 25px 50px;
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
<<<<<<< HEAD
=======
    margin-bottom:25px;
    margin-top:25px;
    margin-left: 50px;
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

  return (
    <Layout>
      <Helmet>
        {/* <title>Your Logistics and Ecommerce Partner | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-partners-couriers-and-warehousing" })}</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "partners-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "partners-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "partners-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "partners-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Shipkoo created a strong partnership with the top-class warehouse operators, couriers and eCommerce platforms. Join Shipkoo now!" />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-partners-couriers-and-warehousing" })} />
        <meta property="og:description" content="Shipkoo created a strong partnership with the top-class warehouse operators, couriers and eCommerce platforms. Join Shipkoo now!" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-partners-couriers-and-warehousing" })} />
        <meta name="twitter:description" content="Shipkoo created a strong partnership with the top-class warehouse operators, couriers and eCommerce platforms. Join Shipkoo now!" />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={aboutUsLinks} activeTab={aboutUsLinks[2].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={aboutUsLinksChinese} activeTab={aboutUsLinksChinese[2].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={aboutUsLinksKorean} activeTab={aboutUsLinksKorean[2].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={aboutUsLinksJapanese} activeTab={aboutUsLinksJapanese[2].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-partners" })}</Breadcrumbs>
      <RelativeWrapperWithImage>
        <WidthWrapper whiteMobile>
          <WhiteContainer>
            <BaseP>{intl.formatMessage({ id: "partners-hero-subheader" })}</BaseP>
            <DetailContent>
              <MainTitle>
                {intl.formatMessage({ id: "partners-hero-header" })}
              </MainTitle>
            </DetailContent>
            <BaseTransparentP>
              {intl.formatMessage({ id: "partners-hero-text" })}
            </BaseTransparentP>
          </WhiteContainer>
        </WidthWrapper>
        <ImageBox>
          <GatsbyImage fluid={data.file.childImageSharp.fluid} alt="shipkoo domestic pick pack and ship services" />
          <MobileFilter />
        </ImageBox>
      </RelativeWrapperWithImage>

      <WidthWrapper>
        <PartnersWrapper>
          <BaseP>{intl.formatMessage({ id: "partners-warehousing" })}</BaseP>
          <Carousel items={warehousingCompanies} className="warehousing" />
          <BaseP>{intl.formatMessage({ id: "partners-couriers" })}</BaseP>
          <Carousel items={couriersCompanies} />
          <BaseP>{intl.formatMessage({ id: "partners-other" })}</BaseP>
          <Carousel items={otherCompanies} />
        </PartnersWrapper>
      </WidthWrapper>
    </Layout>
  );
};

export default PartnerPage;
