import { CarouselCompany } from 'components/Carousel';

import InternationalBridge from 'images/companies/international-bridge.png';
import Didadi from 'images/companies/didadi.png';
import Geodis from 'images/companies/geodis.png';
import AirCity from 'images/companies/air-city.png';

import DHL from 'images/companies/dhl.png';
import Asendia from 'images/companies/asendia.png';
import RoyalMail from 'images/companies/royal-mail.png';
import Fedex from 'images/companies/fedex.png';
import Lozi from 'images/companies/lozi.png';
import USPS from 'images/companies/usps.png';

import Rakuten from 'images/companies/rakuten.png';
import Viwo from 'images/companies/viwo.png';
import eBay from 'images/companies/ebay.png';
import SwiftRobotics from 'images/companies/swift-robotics.png';
import ShenzhenINT from 'images/companies/shenzen-int.png';
import Shopify from 'images/companies/shopify.png';

export const warehousingCompanies: CarouselCompany[] = [
  {
    name: 'Geodis',
    src: Geodis,
  },
  {
    name: 'Lozi',
    src: Lozi,
  },
  {
    name: 'Shenzhen Int.',
    src: ShenzhenINT,
  },
  {
    name: 'Didadi',
    src: Didadi,
  },
];

export const couriersCompanies: CarouselCompany[] = [
  {
    name: 'Royal Mail',
    src: RoyalMail,
  },
  {
    name: 'US Postal Service',
    src: USPS,
  },
  {
    name: 'Air City',
    src: AirCity,
  },
  {
    name: 'DHL',
    src: DHL,
  },
  {
    name: 'FedEx',
    src: Fedex,
  },
  {
    name: 'Asendia',
    src: Asendia,
  },
];

export const otherCompanies: CarouselCompany[] = [
  {
    name: 'eBay',
    src: eBay,
  },
  {
    name: 'Shopify',
    src: Shopify,
  },
  {
    name: 'International Bridge',
    src: InternationalBridge,
  },
  {
    name: 'Rakuten',
    src: Rakuten,
  },
  {
    name: 'Viwo',
    src: Viwo,
  },
  {
    name: 'Swift Robotics',
    src: SwiftRobotics,
  },
];
